import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {SCREEN_SIZE} from "../Configs/Constants";

const NewsSectionHeading = (props) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);
    const renderDesktop = () => {
        return (
            <div className={'pt-5 pb-4'}>
                <div
                    className={`${isMobile ? 'text-wrap bg-white' : 'text-nowrap'} text-center position-absolute robotoCondensedBold font-28 container ${props.fontColor}`}
                    style={{
                        marginTop: '-2vh',
                        zIndex: 1,
                        width: isMobile ? '90%' : props.title?.length > 20 ? '450px' : props.title?.length > 17 ? '300px' : '250px',
                        padding: '8px 0 8px 0px',
                        transform: 'skew(-10deg)',
                        background: (props.bgColor ||
                            'linear-gradient(to bottom, #74dbef, #0092af)'),
                        color: (props.fontColor || '#000000')
                    }}
                >
                    {props.title}
                </div>
                {!props.isShop && <hr style={{height: '3px', color: props.title !== '' ? 'black' : "transparent"}}/>}
            </div>
        );
    }

    const renderMobile = () => {
        return (
            <div className={'pt-2 bebasFont font-25 container'}>
                {props?.title}
            </div>
        );
    }


    return isMobile ? renderMobile() : renderDesktop();
}

NewsSectionHeading.propTypes = {
    title: PropTypes.string.isRequired,
    bgColor: PropTypes.string,
    fontColor: PropTypes.string,
}

export default NewsSectionHeading;
