import DARKBG from '../../assets/images/corporate/BGDark.png';
import {Linkedin} from 'react-bootstrap-icons';
import React, {useState} from 'react';
import Slider from 'react-slick';

const Team = (props) => {
  const {isMobile} = props;
  const [selectedTeam, setSelectedTeam] = useState(1);

  const handleTeamSelect = (teamNumber) => {
    setSelectedTeam(teamNumber);
  };

  const renderTeamSelectSlider = () => {

    const settings = {
      dots: false,
      // className: isMobile ? '' : 'ps-5 ms-5',
      // centerMode: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 2.25,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 2.15,
          },
        },
      ],      // centerPadding: "50px",
      // height:'590px',
      swipe: true,
      slidesToShow: 2.25,
      speed: 500,
    };

    return (
        <Slider style={{color: 'white'}} {...settings}>
          <div
              onClick={() => handleTeamSelect(1)}
              className={'cursor-pointer  align-items-center justify-content-center'}>
            <h3
                style={{
                  border: selectedTeam === 1 ? '2px solid white' : '',
                  color: selectedTeam !== 1 ? '#707070' : '',
                }}
                className={` ${selectedTeam === 1 ?
                    'font-weight-800' :
                    ''} text-center pt-2 pb-2 ps-1 pe-1 font-14 text-nowrap `}>
              LEADERSHIP
            </h3>
          </div>
          <div
              onClick={() => handleTeamSelect(2)}
              className={'cursor-pointer  align-items-center justify-content-center '}>
            <h3
                style={{
                  border: selectedTeam === 2 ? '2px solid white' : '',
                  color: selectedTeam !== 2 ? '#707070' : '',
                }}

                className={` ${selectedTeam === 2 ?
                    'font-weight-800' :
                    ''} text-center pt-2 pb-2 ps-2 pe-3 font-14 text-nowrap `}>
              PRODUCT DEVELOPMENT
            </h3>
          </div>
          <div
              onClick={() => handleTeamSelect(3)}
              className={'cursor-pointer  align-items-center justify-content-center '}>
            <h3
                style={{
                  border: selectedTeam === 3 ? '2px solid white' : '',
                  color: selectedTeam !== 3 ? '#707070' : '',
                }}

                className={` ${selectedTeam === 3 ?
                    'font-weight-800' :
                    ''} text-center pt-2 pb-2 ps-1 pe-3 font-14 text-nowrap `}>
              CONTENT & E-COMMERCE
            </h3>
          </div>
        </Slider>
    );
  };

  const renderTeamSelect = () => {
    return (
        <div className={'container pe-5 ps-5 text-white'}>
          <div className={'row'}>
            <div
                onClick={() => handleTeamSelect(1)}
                style={{
                  border: selectedTeam === 1 ? '2px solid white' : '',
                  color: selectedTeam !== 1 ? '#707070' : '',
                }}
                className={'cursor-pointer col-lg-4 d-flex align-items-center justify-content-center col-md-4 col-sm-4 col-xs-4'}>
              <h3 className={` ${selectedTeam === 1 ?
                  'font-weight-800' :
                  ''} text-center  font-26 pt-2 text-nowrap`}>
                LEADERSHIP
              </h3>
            </div>
            <div
                onClick={() => handleTeamSelect(2)}
                style={{
                  border: selectedTeam === 2 ? '2px solid white' : '',
                  color: selectedTeam !== 2 ? '#707070' : '',

                }}
                className={'cursor-pointer col-lg-4 d-flex align-items-center justify-content-center col-md-4 col-sm-4 col-xs-4 '}>
              <h3 className={` ${selectedTeam === 2 ?
                  'font-weight-800' :
                  ''} text-center font-26 pt-2 text-nowrap`}>
                PRODUCT DEVELOPMENT
              </h3>
            </div>
            <div
                onClick={() => handleTeamSelect(3)}
                style={{
                  border: selectedTeam === 3 ? '2px solid white' : '',
                  color: selectedTeam !== 3 ? '#707070' : '',
                }}
                className={'cursor-pointer col-lg-4 d-flex align-items-center justify-content-center col-md-4 col-sm-4 col-xs-4 '}>
              <h3 className={` ${selectedTeam === 3 ?
                  'font-weight-800' :
                  ''} text-center font-26 pt-2 text-nowrap`}>
                CONTENT & E-COMMERCE
              </h3>
            </div>
          </div>
        </div>
    );
  };

  const renderLeaderShip = () => {
    return (
        <div className={'container'}>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'}
                       src={require(
                           '../../assets/images/corporate/people/kevin.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container  text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >KEVIN LENEHAN
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>CEO
                    & Founder
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/kevin-lenehan-718b4317/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        href={'https://www.linkedin.com/in/kevin-lenehan-718b4317/'}
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid '} src={require(
                      '../../assets/images/corporate/people/hugh.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >HUGH HESSING
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Advisor
                    (London)
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/hugh-hessing-h160'}

                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin style={{
                      borderRadius: '10%'
                      , background: 'white',
                    }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid mt-1'} src={require(
                      '../../assets/images/corporate/people/Barry.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '335px',
                    width: '331px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >BARRY SAGE
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Commercial Advisor (London)
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/barry-sage/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin style={{
                      borderRadius: '10%'
                      , background: 'white',
                    }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
          </div>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'} src={require(
                      '../../assets/images/corporate/people/david.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}
                  />
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >DAVID SAYERS
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Advisor
                    (London)
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/david-sayers-36167413/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'}
                       src={require(
                           '../../assets/images/corporate/people/gerard.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.
</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >GERRY MARRONE
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Advisor
                    (New York)
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/gerrymarrone/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              {/*<img src={require('../assets/images/corporate/comment/comment6.png')} alt={'img1'} style={{objectFit:'contain', height:'203px', width:'390px'}}/>*/}
            </div>
          </div>
        </div>
    );
  };
  const renderProductDevelopment = () => {
    return (
        <div className={'container'}>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'}
                       src={require(
                           '../../assets/images/corporate/people/alfie.png')}
                       alt={'alfie'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container  text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >ALFIE WALKER
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Scrum
                    Master & Product Owner
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/alfie-walker-47bb22187/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        href={'https://www.linkedin.com/in/kevin-lenehan-718b4317/'}
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid '} src={require(
                      '../../assets/images/corporate/people/jake.png')}
                       alt={'jake'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >JAKE TANSEL
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>UI/UX
                    Designer
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/jake-tansel-a6a43b132/'}

                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin style={{
                      borderRadius: '10%'
                      , background: 'white',
                    }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'} src={require(
                      '../../assets/images/corporate/people/Junaid.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >MUHAMMAD JUNAID NAMEEM
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>System
                    Architect
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/mjunaidn/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin style={{
                      borderRadius: '10%'
                      , background: 'white',
                    }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
          </div>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'} src={require(
                      '../../assets/images/corporate/people/jigar.png')}
                       alt={'jigar'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}
                  />
                  <div className="team-txtx h-50 w-75">
                    {/*<h4>KEVIN LENEHAN</h4>*/}
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >JIGAR THAKKAR
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>iOS
                    Developer
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/jigar-thakkar-796973107'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'}
                       src={require(
                           '../../assets/images/corporate/people/ankit.jpeg')}
                       alt={'ankit'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.
</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >ANKIT PATEL
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Android
                    Developer
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/ankit-patel-76a20387/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'} src={require(
                      '../../assets/images/corporate/people/asfand.jpg')}
                       alt={'asfand'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.
</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >ASFAND YAR KHAN
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Web
                    Developer
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/asfand-yar-khan-19081997/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
          </div>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'} src={require(
                      '../../assets/images/corporate/people/tmughal.jpg')}
                       alt={'talha'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.
</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >TALHA JAVAID MUGHAL
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>SQL/Web
                    Developer
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/talha-mughal-93a97b178/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            {/*            <div*/}
            {/*                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>*/}
            {/*              <div>*/}
            {/*                <div className={'team-imgbx'}>*/}
            {/*                  <img className={'img-fluid'} src={*/}
            {/*                    'https://cdn-icons-png.flaticon.com/512/6073/6073874.png'}*/}
            {/*                       alt={'talha'} style={{*/}
            {/*                    objectFit: 'contain',*/}
            {/*                    height: '351px',*/}
            {/*                    width: '351px',*/}
            {/*                  }}/>*/}
            {/*                  <div className="team-txtx h-50 w-75">*/}
            {/*                    /!*<h4>KEVIN LENEHAN</h4>*!/*/}
            {/*                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.*/}
            {/*</span>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*                <div*/}
            {/*                    className={'container text-start ps-4 ms-3 text-white'}>*/}
            {/*                  <div*/}
            {/*                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}*/}
            {/*                      style={{fontWeight: 'bold'}}*/}
            {/*                  >SAAD SOHAIL*/}
            {/*                  </div>*/}
            {/*                  <div*/}
            {/*                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Web Developer*/}
            {/*                  </div>*/}
            {/*                  <a*/}
            {/*                      target="_blank"*/}
            {/*                      rel="noreferrer"*/}
            {/*                      href={'https://www.linkedin.com/in/gerrymarrone/'}*/}
            {/*                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>*/}
            {/*                    <Linkedin*/}
            {/*                        style={{*/}
            {/*                          borderRadius: '10%'*/}
            {/*                          , background: 'white',*/}
            {/*                        }} size={35} color={'#0077b5'}/></a>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </div>*/}

          </div>
        </div>
    );
  };
  const renderContentEComm = () => {
    return (
        <div className={'container'}>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'}
                       src={require(
                           '../../assets/images/corporate/people/louis.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container  text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >LOUIS LENEHAN
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Content
                    Manager
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/louis-lenehan-792411102/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid '} src={require(
                      '../../assets/images/corporate/people/braydon.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >BRAYDON FORD
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>E-Commerce
                    Analyst
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/braydon-ford-899b20187/'}

                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin style={{
                      borderRadius: '10%'
                      , background: 'white',
                    }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'} src={require(
                      '../../assets/images/corporate/people/william.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.

</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >WILLIAM PEARN
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Content
                    Analyst
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/william-pearn-780821145/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin style={{
                      borderRadius: '10%'
                      , background: 'white',
                    }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
          </div>
          <div className={'row mt-5'}>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              <div>
                <div className={'team-imgbx'}>
                  <img className={'img-fluid'}
                       src={require(
                           '../../assets/images/corporate/people/archie.png')}
                       alt={'img1'} style={{
                    objectFit: 'contain',
                    height: '351px',
                    width: '351px',
                  }}/>
                  <div className="team-txtx h-50 w-75">
                    <span className={'text-wrap'}>12 years’ industry experience. 7 years in motorsport having been both rights holder and agency side. Extensive network of contacts and diverse industry knowledge, underpinned with excellent client relationship management.
</span>
                  </div>
                </div>
                <div
                    className={'container text-start ps-4 ms-3 text-white'}>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}
                      style={{fontWeight: 'bold'}}
                  >ARCHIE AMPOFO
                  </div>
                  <div
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12'}>Content
                    Analyst
                  </div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/archie-ampofo-5482b718a/'}
                      className={'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 '}>
                    <Linkedin
                        style={{
                          borderRadius: '10%'
                          , background: 'white',
                        }} size={35} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div
                className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
              {/*<img src={require('../assets/images/corporate/comment/comment6.png')} alt={'img1'} style={{objectFit:'contain', height:'203px', width:'390px'}}/>*/}
            </div>
          </div>
        </div>
    );
  };
  const renderTeamLaptop = () => {
    return (
        <div
            style={{
              backgroundImage: `url("${DARKBG}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: selectedTeam === 2 ? '1700px' : '1200px',
            }}
            className={'pt-5 pb-5'}
        >
          <h1 className={'prometoFont  text-white'}
              style={{fontSize: '50px'}}
          >
            TEAM
          </h1>
          {renderTeamSelect()}
          {selectedTeam === 1 ?
              renderLeaderShip() :
              selectedTeam === 2 ?
                  renderProductDevelopment() :
                  renderContentEComm()}
        </div>

    );
  };

  const renderMobileLeaderShip = () => {
    return (
        <div className={'container'}>
          <div className={'row mt-5'}>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'}
                     src={require(
                         '../../assets/images/corporate/people/kevin.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >KEVIN LENEHAN
                  </div>
                  <div className={'cl-6'}>CEO & Founder</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/kevin-lenehan-718b4317/'}
                      className={'col-6'}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/hugh.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >HUGH HESSING
                  </div>
                  <div className={'col-12'}>Advisor (London)</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/hugh-hessing-h160'}

                      className={'col-6'}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/Barry.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >BARRY SAGE
                  </div>
                  <div className={'col-12'}>Commercial Advisor (London)</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/barry-sage/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/david.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}


                />
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >DAVID SAYERS
                  </div>
                  <div className={'col-12'}>Advisor (London)</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/david-sayers-36167413/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/gerard.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >GERRY MARRONE
                  </div>
                  <div className={'col-12'}>Commercial Advisor (London)</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/gerrymarrone/'}

                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobileProductDevelopment = () => {
    return (
        <div className={'container'}>
          <div className={'row mt-5'}>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'}
                     src={require(
                         '../../assets/images/corporate/people/alfie.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >ALFIE WALKER
                  </div>
                  <div className={'col-12'}>Scrum Master & Product Owner</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/alfie-walker-47bb22187/'}
                      className={'col-6'}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/jake.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >JAKE TANSEL
                  </div>
                  <div className={'col-12'}>UI/UX Designer</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/jake-tansel-a6a43b132/'}

                      className={'col-6'}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/Junaid.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >MUHAMMAD JUNAID NAEEM
                  </div>
                  <div className={'col-12'}>System Architect</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/mjunaidn/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/jigar.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}
                />
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >JIGAR THAKAR
                  </div>
                  <div className={'col-12'}>iOS Developer</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/jigar-thakkar-796973107'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/ankit.jpeg')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >ANKIT PATEL
                  </div>
                  <div className={'col-12'}>Android Developer</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/ankit-patel-76a20387/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/asfand.jpg')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >ASFAND YAR KHAN
                  </div>
                  <div className={'col-12'}>Web Developer</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/asfand-yar-khan-19081997/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/tmughal.jpg')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >TALHA JAVAID MUGHAL
                  </div>
                  <div className={'col-12'}>SQL/Web Developers</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/asfand-yar-khan-19081997/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobileContentEcom = () => {
    return (
        <div className={'container'}>
          <div className={'row mt-5'}>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'}
                     src={require(
                         '../../assets/images/corporate/people/louis.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >LOUIS LENEHAN
                  </div>
                  <div className={'cl-6'}>Content Manager</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/louis-lenehan-792411102/'}
                      className={'col-6'}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/braydon.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >BRAYDON FORD
                  </div>
                  <div className={'col-12'}>E-Commerce Analyst</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/braydon-ford-899b20187/'}
                      className={'col-6'}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>

            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/william.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >WILLIAM PEARN
                  </div>
                  <div className={'col-12'}>Content Analyst</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/william-pearn-780821145/'}
                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
            <div className={'col-6 p-2'}>
              <div>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/people/archie.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                }}/>
                <div
                    className={'container text-start  text-white'}>
                  <div className={'col-12'}
                       style={{fontWeight: 'bold'}}
                  >ARCHIE AMPOFO
                  </div>
                  <div className={'col-12'}>Content Analyst</div>
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href={'https://www.linkedin.com/in/archie-ampofo-5482b718a/'}

                      className={'col-6 '}><Linkedin
                      style={{
                        borderRadius: '10%'
                        , background: 'white',
                      }} size={25} color={'#0077b5'}/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderTeamMobile = () => {
    return (
        <div
            style={{
              backgroundImage: `url("${DARKBG}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: selectedTeam === 2 ? '1400px' : '1020px',
            }}
            className={'pb-5 pt-2'}
        >
          <h1 className={'prometoFont pt-3 pb-3 text-white'}
              style={{fontSize: '32px'}}
          >
            TEAM
          </h1>
          {renderTeamSelectSlider()}
          {selectedTeam === 1 ?
              renderMobileLeaderShip() :
              selectedTeam === 2 ?
                  renderMobileProductDevelopment() :
                  renderMobileContentEcom()}
        </div>

    );
  };

  return isMobile ? renderTeamMobile() : renderTeamLaptop();

};

export default Team;
