import BGDark from '../../assets/images/corporate/component8204@3x.png';
import ICON from '../../assets/images/corporate/icon.png';
import React from 'react';

const BecomingTheBiggest = (props) => {
const {isMobile} = props;


  const renderLaptop = () => {
    return (
        <>
          <div className={`container pt-5 ps-5  pe-5`}>
            <div
                className={'d-flex justify-content-center mt-5 pt-3 align-items-center'}
                style={{background: '#74dbef'}}
            >
              <h1 className={`prometoFont ${isMobile ?
                  'font-50' :
                  'font-60'} `}>
                BECOMING THE
                BIGGEST SPORTS</h1>
            </div>
          </div>
          <div className={'container mt-3 pe-5 ps-5'}>
            <div
                className={' pt-3'}
                style={{background: '#74dbef'}}
            >
              <h1 className={`prometoFont ${isMobile ?
                  'font-50' :
                  'font-60'} `}>FANS COMMUNITY IN
                THE WORLD</h1>
            </div>
          </div>
        </>
    );
  };

  const renderMobile = () => {
    return (
        < div
            className={'ps-5 text-center mt-5 pt-5 w-75 justify-content-center'}>
          <h1 className={`p-2  prometoFont   w-fit-content text-nowrap  ${isMobile ?
              'font-32' :
              'font-60'} `
          }
              style={{
                background: '#74dbef',
              }}

          >
            BECOMING THE
          </h1>
          <h1 className={`p-2 prometoFont w-fit-content text-nowrap  ${isMobile ?
              'font-32' :
              'font-60'} `}
              style={{background: '#74dbef'}}
          >
            BIGGEST SPORTS
          </h1>
          <h1 className={` p-2 prometoFont text-nowrap w-fit-content ${isMobile ?
              'font-32' :
              'font-60'} `}
              style={{background: '#74dbef'}}
          >FANS COMMUNITY </h1>
          <h1 className={`p-2 prometoFont w-fit-content text-nowrap  ${isMobile ?
              'font-32' :
              'font-60'} `}
              style={{background: '#74dbef'}}
          >IN
            THE WORLD</h1>
        </div>
    );
  };

  return (
      <div
          style={{
            backgroundImage: `url("${BGDark}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: isMobile ? '337px' : '677px',
            zIndex: '-1',
            marginTop: isMobile && '-80px',
          }}

      >

        {isMobile ? renderMobile() : renderLaptop()}

        {!isMobile && <div className={'container pe-5 ps-5'}
                           style={{
                             backgroundImage: `url("${ICON}")`,
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat',
                             width: '614px',
                             height: '292px',
                           }}
        >
        </div>
        }        </div>
  );

};

export default BecomingTheBiggest
