import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';

const Advertising = (props) => {
  const {isMobile} = props;
  const [backgroundSize,setBackGroundSize] = useState('1050px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('1050px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('1050px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
        <div
            style={{height:backgroundSize}}
            className={'container-fluid blackBackgroundTwo flex-grow-1'}>
          <div className={'container pt-3'}>
            <div className={'row'}>
              <div className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12'}>
                <div className={'row justify-content-center'}>
                <span
                    className={'text-white prometoFont font-48 font-weight-800 pt-3 text-center'}>
                  ADVERTISING
                </span>
                </div>
                <div className={'row justify-content-center pt-3'}>
                  <span
                      className={'font-fanzine font-24 font-weight-800 text-center'}>
                  Place your adverts in our sport apps, websites and social media pages.
                  </span>
                </div>
                <div className={'row justify-content-center pt-5'}>
                  <img src={require('../../assets/images/webAdvertising.png')}
                       alt={'web advertising'}
                       style={{
                         height: 682,
                         width: 'auto',
                       }}
                  />
                </div>
                <div className={'row justify-content-center'}>
                  <Link to={'/advertisement'}
                        className={'text-decoration-none'}>
                    <Button

                        variant={'outline-light'}
                        style={{
                          fontSize: 20,
                          width: '291px',
                          height: '76px',
                          margin: '21px 329px 0 330px',
                          padding: '23px 57px 24px',
                          border: 'solid 2px #fff',
                        }}
                    >
                      LEARN MORE
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobileView = () => {
    return (
        <div
            className={'blackBackgroundTwo container-fluid'}>
          <div className={'row pb-5'}>
            <span
                className={' font-28 prometoFont text-white font-weight-800 text-center pt-3'}
            >
            ADVERTISING
          </span>
            <span
                style={{
                  color: '#74dbef',
                }}
                className={'font-fanzine font-16 font-weight-300 text-center text-decoration-none'}>
              Place your adverts in our sport apps,
              websites and social media pages.
            </span>
            <div
                className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 pt-3 d-flex align-items-center justify-content-center'}>
              <img
                  src={require('../../assets/images/webAdvertising.png')} alt={'web advertising'}
                  style={{
                    height:'296px',
                    width:'auto'
                  }}
              />
            </div>
            <div
                className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12  d-flex align-items-center justify-content-center'}>
              <Link to={'/advertisement'}
                    className={'text-decoration-none'}>
                <Button

                    variant={'outline-light'}
                    style={{
                      fontSize: 20,
                      width: '234px',
                      height: '61px',
                      margin: '55.7px 70px 0 48px',
                      padding: '19px 43px 18px 44px',
                      border: 'solid 1px #fff',
                    }}
                >
                  LEARN MORE
                </Button>
              </Link>
            </div>
          </div>

        </div>
    );
  };

  const renderMain = () =>
      isMobile ? renderMobileView() : renderDesktopView();

  return renderMain();
};

export default Advertising;
