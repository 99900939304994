import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';

const Partners = (props) => {
  const {isMobile} = props;
  const [backgroundSize,setBackGroundSize] = useState('600px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('600px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('600px')
    }
  }, [window.screen.width]);
  const renderDesktopView = () => {
    return (
        <div
            style={{
              height: backgroundSize,
            background:require('../../assets/images/v2/adobeStock480108027Converted01@3x.png'),
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',

            }}
            className={'container-fluid flex-grow-1'}>
          <div className={'container pt-3'}>
            <div className={'row'}>
              <div className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12'}>
                <div className={'row justify-content-center'}>
                <span
                    className={'text-dark prometoFont font-48 font-weight-800 text-center pb-5'}>
                  Partners
                </span>
                </div>
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center'}>
                <img
                    src={require('../../assets/images/2560PxSkySportsLogo2017Svg@3x.png')} alt={'skysports'}
                    style={{
                      width:'auto',
                      height:'64px'
                    }}
                />
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center'}>
                <img
                    src={require('../../assets/images/snackLogo@3x.png')} alt={'snack media'}
                    style={{
                      height:'231px',
                      width:'auto'
                    }}
                />
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center'}>
                <img
                    src={require('../../assets/images/sportSkinsLogo@3x.png')} alt={'sportsskin'}
                    style={{
                      height:'231px',
                      width:'auto'
                    }}
                />
              </div>

              <div
                  className={'col-4 d-flex align-items-center justify-content-center'}>
                <img
                    src={require('../../assets/images/888sport-logo.png')} alt={'web advertising'}
                    style={{
                      height:'196px',
                      width:'auto'
                    }}
                />
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center'}>
                <img
                    src={require('../../assets/images/givemensport.png')} alt={'web advertising'}
                    style={{
                      height:'109px',
                      width:'auto'
                    }}
                />
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center'}>
                <img
                    src={require('../../assets/images/2732738284HowToCreateContentThatStandsOutLiga1@3x.png')} alt={'web advertising'}
                    style={{
                      height:'122px',
                      width:'auto'
                    }}
                />
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobileView = () => {
    return (
        <div
            style={{
              background:require('../../assets/images/v2/adobeStock480108027Converted01@3x.png'),
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
            className={'container-fluid'}>
          <div className={'row pb-5'}>
            <span
                className={' font-28 text-dark prometoFont font-weight-800 text-center pt-3'}
            >
            Partners
          </span>
            <div
                className={'col-6 pt-3'}>
              <img
                  className={'mt-4'}
                  src={require('../../assets/images/2560PxSkySportsLogo2017Svg@3x.png')} alt={'skysports'}
                  style={{
                    width:'119px',
                    height:'25px'
                  }}
              />
            </div>
            <div
                className={'col-6 pt-3 d-flex align-items-center justify-content-center'}>
              <img
                  src={require('../../assets/images/sportSkinsLogo@3x.png')} alt={'sportsskin'}
                  style={{
                    height:'91px',
                    width:'69px'
                  }}
              />
            </div>
            <div
                className={'col-6 pt-3 d-flex align-items-center justify-content-center'}>
              <img
                  src={require('../../assets/images/snackLogo@3x.png')} alt={'snack media'}
                  style={{
                    height:'91px',
                    width:'92px'
                  }}
              />
            </div>
            <div
                className={'col-6 pt-3 d-flex align-items-center justify-content-center'}>
              <img
                  src={require('../../assets/images/givemensport.png')} alt={'web advertising'}
                  style={{
                    height:'43px',
                    width:'136px'
                  }}
              />
            </div>
              <div
                className={'col-6 mt-3 pt-3 d-flex align-items-center justify-content-center'}>
              <img
                  src={require('../../assets/images/888sport-logo.png')} alt={'web advertising'}
                  style={{
                    height:'77px',
                    width:'77px'
                  }}
              />
            </div>
            <div
                className={'col-6 mt-2 d-flex align-items-center justify-content-center'}>
              <img
                  src={require('../../assets/images/2732738284HowToCreateContentThatStandsOutLiga1@3x.png')} alt={'web advertising'}
                  style={{
                    height:'48px',
                    width:'138px'
                  }}
              />
            </div>
          </div>

        </div>
    );
  };

  const renderMain = () =>
      isMobile ? renderMobileView() : renderDesktopView();

  return renderMain();
};

export default Partners;
