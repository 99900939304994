import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import {useEffect} from "react";
import {getHostname} from "../../CustomFunctions/getHostname";
import {COLORS, LOGO} from "../../Configs/Targets";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import HTMLEllipsis from "react-lines-ellipsis/lib/html.modern";
import {Link, useParams} from "react-router-dom";

const MadNewsCard = (props) => {
    const {news, isMobile} = props;
    const {team} = useParams();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let textColor = COLORS[target];
    let logo = LOGO[target];

    useEffect(() => {
        console.log('news', news);
    }, [news, isMobile]);

    const renderMobile = () => {
        return renderDesktop();
    }

    const renderTags = (tag) => {
        return (
            <div
                className={'text-mad-tag jost font-weight-700 font-16 border-mad-tag rounded-pill ps-3 pe-3 pt-2 pb-2 me-2 mt-2'}>
                <FontAwesomeIcon icon={faCircle} color={textColor}/>
                {" "}
                {tag}
            </div>
        );
    }

    const renderDate = () => {
        return (
            <div
                className={'d-flex justify-content-between ms-2 me-2 mt-4 text-mad-tag jost font-weight-500 font-16 align-items-end'}>
                <div>
                    {news.date} - {news.reading_time} min read
                </div>
                <div>
                    <img src={logo} height={56} alt={`${target} logo`}/>
                </div>
            </div>
        );
    }

    const renderDesktop = () => {
        return (
            <Link to={`/news/${team}/${news.slug}`} className={'text-black text-decoration-none'}>
                <Card className={'rounded-0'}>
                    <img alt={news.title} src={news.image} className={'m-3 rounded-0 img-fluid'}/>
                    <Card.Body>
                        <Card.Title className={'jost font-weight-700 font-30 ms-2 me-2'}>
                            <HTMLEllipsis
                                unsafeHTML={news.title}
                                maxLine="2"
                                ellipsis="..."
                                basedOn="letters"
                            />
                        </Card.Title>
                        <Card.Text>
                            <div className={'d-flex flex-wrap'}>
                                {
                                    news.tags.map((tag) => {
                                        return renderTags(tag);
                                    })
                                }
                            </div>
                            {renderDate()}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Link>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

MadNewsCard.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    news: PropTypes.object.isRequired
}

MadNewsCard.defaultProps = {
    isMobile: false,
}

export default MadNewsCard;
