import PicAudience from '../../assets/images/corporate/component8801@3x.png';
import React from 'react';

const Audience = (props) => {
  const {isMobile} = props;

  const renderLaptop = () => {
    return (
        <div
            style={{
              backgroundImage: `url("${PicAudience}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '80vh',
            }}
        >
          <h1 className={'prometoFont pt-5'}
              style={{fontSize: '50px'}}
          >
            AUDIENCE
          </h1>

        </div>

    );
  };
  const renderMobile = () => {
    return (
        <div
            style={{
              backgroundImage: `url("${require(
                  '../../assets/images/v2/adobeStock480108027Converted01@3x.png')}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '797px',
            }}
            className={'pt-3 mt-5'}
        >
          <h1 className={'prometoFont pt-3'}
              style={{fontSize: '32px'}}
          >
            AUDIENCE
          </h1>

          <img src={require('../../assets/images/v2/component8511@3x.png')}
               className={'img-fluid'} alt={'img-12'}
               style={{height: '247px', width: '361px'}}/>
          <img src={require('../../assets/images/v2/component8501@3x.png')}
               className={'img-fluid'} alt={'img-12'}
               style={{height: '366px', width: '331px'}}/>


        </div>

    );
  };

  return isMobile ? renderMobile() : renderLaptop();

};

export default Audience;
