import React, {useEffect, useState} from 'react';
import {withRouter} from '../../CustomFunctions/withRouter';
import {get} from '../../Axios/get';
import {CRICKET, MLB, NBA, NCAA, NewsEndPoint, NFL,} from '../../Axios/EndPoints';
import {Button, Toast, Tooltip} from 'react-bootstrap';
import moment from 'moment-timezone';
import {COLORS, COLORS_RGBA, LOGO, TEAM_NAME, TEXT_COLOR} from '../../Configs/Targets';
// import News from "../Components/Homepage/News";
// import {chunk} from "../Components/Helper/chunk";
import {getHostname} from '../../CustomFunctions/getHostname';
import {SCREEN_SIZE} from '../../Configs/Constants';
import Loader from '../../Components/Loader/Loader';
import NewsSectionHeading from "../../Components/NewsSectionHeading";
import {useLocation} from 'react-router-dom';
import Footer from '../../Components/Footer';
import FanzineSportsNavBar from '../../Components/Navbar/FanzineSportsNavBar';
import {FiCopy} from "react-icons/fi";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";
import MadNewsCard from "../../Components/Cards/MadNewsCard";
import Card from "react-bootstrap/Card";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple, faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {AndroidSVG} from "../../Constants/JS/AndroidSVG";
import data from "bootstrap/js/src/dom/data";

import { TwitterTweetEmbed } from 'react-twitter-embed';

const NewSingleNewsPage = (props) => {
    let newsId = props.match.params.id;
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let teamName = TEAM_NAME[target];
    let color = COLORS[target];
    let colorRgba = COLORS_RGBA[target];
    let logo = LOGO[target];
    const fontColor = TEXT_COLOR[target];

    const location = useLocation();
    const store = useSelector(state => state);

    let [iframeHeight, setIframeHeight] = useState(0);
    let [iframeData, setIframeData] = useState('null');
    let [latestNews, setLatestNews] = useState([]);
    let [relatedStories, setRelatedStories] = useState([]);
    let [news, setNews] = useState(null);
    let [newsContent, setNewsContent] = useState([]);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    const [showToast, setShowToast] = useState(false);
    let [isTwitterScriptLoaded, setIsTwitterScriptLoaded] = useState(false)

    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../assets/images/football-shots-you-need-to-know.jpg');
    };

    const fetchSingleNews = () => {

        let URL = '';

        switch (location.pathname.split('/')[1]) {
            case 'football':
                URL += NewsEndPoint;
                break;
            case 'cricket':
                URL += CRICKET.NewsEndPoint;
                break;
            case 'nfl':
                URL += NFL.NewsEndPoint;
                break;
            case 'nba':
                URL += NBA.NewsEndPoint;
                break;
            case 'ncaa':
                URL += NCAA.NewsEndPoint;
                break;
            case 'mlb':
                URL += MLB.NewsEndPoint;
                break;
            default:
                URL += NewsEndPoint;
                break;

        }


        get(URL + `/slug/${newsId}`)
            .then((response) => {
                setNews(response.data);
                if (!response.data.is_snack) {
                    // setNewsContent()
                    window.open(response.data.url, '_self');
                } else {
                    /*if (target !== 'fanzine') {
                        window.open(`https://fanzine.com/football/${response.data.main_team}/${response.data.slug}`, '_self');
                    }*/
                    let val = response.data.text;

                    let parser = new DOMParser();
                    let valAsHtml = parser.parseFromString(val, 'text/html');

                    let childNodes = valAsHtml.body.childNodes;
                    window.childNodes = childNodes;

                    let newArr = [];

                    childNodes.forEach((value) => {
                        value.classList += ' font-18 jost font-weight-400';
                        value.childNodes.forEach((aVal) => {
                            if (aVal.tagName === 'FIGCAPTION' && aVal.querySelector('span')) {
                                aVal.querySelector('span').classList += ' font-16 jost font-weight-600 text-black text-decoration-none font-italic';
                            }
                            return aVal.classList += ' font-18 jost font-weight-800 text-black text-decoration-none';
                        })
                        newArr.push(value);
                    });

                    setNewsContent(newArr);

                    if (target === 'fanzine' || response.data.is_mad) {
                        setLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const fetchLatestNews = () => {
        let params = `?mad=1&limit=10`;
        if (target === 'fanzine') {
            params += '&main_site=1';
        }
        get(NewsEndPoint + params)
            .then((response) => {
                console.log('response.data', response.data);
                let tempLatestNews = [];
                response.data.map((data) => {
                    let timeDifference = moment.utc(data.datetime)
                        .local()
                        .startOf('seconds')
                        .fromNow();
                    let tempNews = {
                        news_id: data.news_id,
                        slug: data.slug,
                        image: data.image,
                        title: data.title,
                        url: data.url,
                        reading_time: data.reading_time,
                        time: timeDifference,
                        date: moment(data.datetime).format('MMM D, YYYY'),
                        publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        main_team: data.main_team,
                        featured_image_caption: data.featured_image_caption,
                        featured_image_alt: data.featured_image_alt,
                        tags: data.tagNames,
                    };

                    if (data.slug !== newsId) {
                        return tempLatestNews.push(tempNews);
                    }
                });
                setLatestNews(tempLatestNews);
            });
    };

    useEffect(() => {
        setLoading(true);
        fetchSingleNews();
        fetchLatestNews();
    }, [newsId]);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect((props) => {
        let desiredSource = 'https://platform.twitter.com/widgets.js';
        let scripts = document.getElementsByTagName('script');
        let alreadyLoaded = false;

        for (let scriptIndex in document.scripts) {
            if (!alreadyLoaded && desiredSource === scripts[scriptIndex].src) {
                alreadyLoaded = true;
                setIsTwitterScriptLoaded(alreadyLoaded);
            }
        }
        if (!alreadyLoaded) {
            // Run your code in this block?
            setIsTwitterScriptLoaded(false);
        }

        // console.log('alreadyLoaded -',alreadyLoaded)

    }, [])

    window.twttr && window.twttr.widgets.load();

    const renderSocialShare = () => {

        const URL2Share = window.location.href;
        const copy2ClipBoard = (url) => {
            setShowToast(!showToast);
            try {
                navigator?.clipboard?.writeText(url);
            } catch (error) {
                console.error(error);
            }
        }


        const tooltip = (
            <Tooltip id="tooltip">
                <strong>Holy guacamole!</strong> Check this info.
            </Tooltip>
        )


        return (
            <div className={`container ${isMobile ? 'mt-2' : 'mt-n5'}`}>
                <div className={'row d-flex justify-content-center'}>
                    <div
                        className={isMobile ? 'col-2 bg-white' : 'col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'}>
                        <TwitterShareButton url={URL2Share}
                                            className={`${isMobile ? '' : 'bg-white'} border-radius-none border-0`}
                                            style={{
                                                width: isMobile ? 40 : 100
                                            }}
                        >
                            <TwitterIcon size={40}
                                         bgStyle={{
                                             fill: '#fff',
                                         }}
                                         iconFillColor={'#066'}
                            />
                        </TwitterShareButton>
                    </div>
                    <div
                        className={isMobile ? 'col-2 bg-white' : 'col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'}>
                        <FacebookShareButton url={URL2Share}
                                             className={`${isMobile ? '' : 'bg-white'} border-radius-none border-0`}
                                             style={{
                                                 width: isMobile ? 40 : 100
                                             }}
                        >
                            <FacebookIcon size={40} bgStyle={{
                                fill: '#fff'
                            }} iconFillColor={'#666'}/>
                        </FacebookShareButton>
                    </div>
                    <div
                        className={isMobile ? 'col-2 bg-white' : 'col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'}>
                        <LinkedinShareButton url={URL2Share}
                                             className={`${isMobile ? '' : 'bg-white'} border-radius-none border-0`}
                                             style={{
                                                 width: isMobile ? 40 : 100
                                             }}
                        >
                            <LinkedinIcon size={40} bgStyle={{
                                fill: '#fff',
                            }} iconFillColor={'#666'}/>
                        </LinkedinShareButton>
                    </div>
                    <div
                        className={isMobile ? 'col-2 bg-white' : 'col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'}>
                        <EmailShareButton url={URL2Share}
                                          className={`${isMobile ? '' : 'bg-white'} border-radius-none border-0`}
                                          style={{
                                              width: isMobile ? 40 : 100
                                          }}
                        >
                            <EmailIcon size={40} bgStyle={{
                                fill: '#fff'
                            }} iconFillColor={'#666'}/>
                        </EmailShareButton>
                    </div>
                    <div
                        className={isMobile ? 'col-2 bg-white' : 'col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'}>
                        <Button
                            onClick={() => copy2ClipBoard(URL2Share)}
                            className={'border-radius-none bg-white border-0'}
                            style={{
                                width: isMobile ? 40 : 100,
                                height: 40
                            }}
                        >
                            <FiCopy size={20} color={'#666'}/>
                        </Button>
                        <Toast
                            style={{
                                zIndex: 9999,
                                width: isMobile ? 50 : 100,
                                height: 30,
                            }}
                            className={'position-absolute d-flex align-items-center justify-content-center'}
                            onClose={() => setShowToast(false)}
                            show={showToast} delay={2000} autohide>
                            <Toast.Body
                                className={'font-12 text-center d-flex align-items-center justify-content-center'}>Copied!</Toast.Body>
                        </Toast>

                    </div>
                </div>
            </div>
        )

    }
    const renderNewsHead = () => {
        return (
            <>
                <div className={'h1 font-40 text-center jost font-weight-800 mt-3'}
                     dangerouslySetInnerHTML={{__html: news.title}}/>
                <div>
                    <span
                        className={`justify-content-center d-flex text-light-gray-single-news font-18 font-weight-600 jost`}>
                        <span>{moment(news.datetime).format('MMM D, YYYY')} - {news?.reading_time} min read</span>
                    </span>
                </div>
                <div
                    className={`${isMobile ? '' : 'p-5'} col-12`}>
                    <img
                        style={{
                            objectFit: 'contain'
                        }}
                        src={news.image}
                        alt={news.featured_image_alt}
                        className={`w-100 mt-n5 p-4 Fanzine_Logo`}/>
                    <span
                        className={'d-flex justify-content-center font-16 mt-n4 jost font-weight-600 text-light-gray-single-news font-italic '}
                        dangerouslySetInnerHTML={{__html: news.featured_image_caption}}/>
                </div>
                {renderSocialShare()}
            </>
        );
    };

    function renderRelatedStories() {
        return (
            <div className="mb-4">
                <NewsSectionHeading title={'You might also like'} bgColor={'transparent'} fontColor={'#000000'}
                                    isShop={true}/>
                <div className={'row mt-5'}>
                    {latestNews.length ?
                        (
                            latestNews.map((value) => (
                                value.slug !== newsId &&
                                <div className={'col-lg-4 pt-2 col-md-4 col-sm-12 col-xs-12'}>
                                    <MadNewsCard
                                        isMobile={isMobile}
                                        news={value}
                                    />
                                </div>
                            ))
                        )
                        : null}
                </div>
            </div>
        );
    }

    const getTweetIdFromTwitterHtml = (htmlString) => {
        const tweetIdRegex = /twitter\.com\/[^\/]+\/status\/(\d+)/i;
        const match = htmlString.match(tweetIdRegex);
        return match ? match[1] : null;
    };
    const renderPublishedBy = () => {
        return (
            <Card className={'rounded-0 border-0 shadow'}>
                <Card.Body>
                    <Card.Title className={'jost font-weight-800 font-18 ms-2 me-2 text-mad-tag'}>
                        Published By
                    </Card.Title>
                    <div style={{
                        backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 0), ${color}, ${colorRgba + '0)'})`,
                        height: '5px',
                        width: '40%',
                        margin: '25px 20px 24px',
                    }}/>
                    <Card.Text>
                        <div className={`d-flex ${!isMobile && 'flex-wrap'} justify-content-start align-items-center`}>
                            <img alt={news.title} src={logo} className={'rounded-0'} height={isMobile ? 70 : 120}
                                 width={isMobile ? 70 : 120}/>
                            <div className={isMobile ? 'ms-2' : 'ms-5'}>
                                <div className={'jost font-20 font-weight-800 text-black text-capitalize mb-3'}>
                                    {teamName}
                                </div>
                                <div className={'jost font-18 font-weight-500 text-black text-capitalize'}>
                                    The number one independent {store?.misc?.appInit?.team_name || teamName} fan app &
                                    website.
                                </div>
                                {!isMobile && renderSocialNav()}
                            </div>
                        </div>
                        {
                            isMobile &&
                            <div className={'d-flex justify-content-center'}>
                                {renderSocialNav()}
                            </div>
                        }
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }


    function renderSocialNav() {
        return (
            <div className={'mt-3'}>
                <a rel={'noreferrer'} href={store.misc.appInit &&
                    store.misc.appInit.non_betting_urls.ios_appstore_url}
                   target={'_blank'}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faApple} className={fontColor} size={'2x'}/>
                </a>
                <a rel={'noreferrer'} href={store.misc.appInit &&
                    store.misc.appInit.non_betting_urls.android_play_store_url}
                   target={'_blank'}
                   className={'text-decoration-none ms-4'} style={{color: '#686868'}}>
                    <AndroidSVG height={50} width={40}
                        // fill={'white'}
                        // fill={fontColor}
                                className={`ms-2 ${fontColor}`}
                                style={{
                                    fill: `#${color}`
                                }}
                    />
                    {/*<FontAwesomeIcon icon={faAndroid} className={`  ${fontColor}`} size={'2x'}/>*/}
                </a>
                <a rel={'noreferrer'}
                   href={store.misc.appInit && store.misc.appInit.facebook_url}
                   target={'_blank'}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faFacebook} className={` ms-4 ${fontColor}`}
                                     size={'2x'}/>
                </a>
                <a rel={'noreferrer'}
                   href={store.misc.appInit && store.misc.appInit.twitter_url}
                   target={'_blank'}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faTwitter} size={'2x'}
                                     className={`ms-4 ${fontColor}`}/>
                </a>
                <a rel={'noreferrer'}
                   href={store.misc.appInit && store.misc.appInit.instagram_url}
                   target={'_blank'}
                   className={'text-decoration-none'} style={{color: '#686868'}}>
                    <FontAwesomeIcon icon={faInstagram} size={'2x'}
                                     className={`ms-4 ${fontColor}`}/>
                </a>
            </div>
        );
    }

    const renderSnack = () => {

        return (
            <>
                <div
                    className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 ${!isMobile ?
                        'container d-flex flex-row justify-content-center' :
                        'mt-5 pt-2'}`}>
                    <div className={'container'}>
                        {!isMobile && <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>}

                        <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className={`col-12`}>
                                    {renderNewsHead()}
                                    {isMobile && <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>}
                                    <div className={`${isMobile ? '' : 'container mt-3 p-5'}`}>
                                        {
                                            newsContent.map((value, index, newsContent) => {
                                                // console.log("value at index >>> ", index, "  >>>>>", value?.innerHTML)
                                                let showAdIndex;

                                                let htmlString = value?.innerHTML;
                                                const tweetId = getTweetIdFromTwitterHtml(htmlString);

                                                // console.log("tweetId >>>", tweetId)
                                                if ((index === 2 && newsContent[3].tagName === 'FIGURE') || (index === 3 && value.tagName === 'FIGURE')) {
                                                    showAdIndex = 3;
                                                } else {
                                                    showAdIndex = 2;
                                                }
                                                return (
                                                    <>
                                                        {/*{*/}
                                                        {/*    value.tagName === 'P' ?*/}
                                                        {/*        <div className={'font-18 jost font-weight-400'}*/}
                                                        {/*             dangerouslySetInnerHTML={{__html: value.outerHTML}}/>*/}
                                                        {/*        :*/}
                                                        {/*        <div*/}
                                                        {/*            className={`w-100 ${isMobile ? 'text-center' : ' d-flex justify-content-center'}`}>*/}
                                                        {/*            <div className={'font-18 jost font-weight-400'}*/}
                                                        {/*                 dangerouslySetInnerHTML={{__html: value.outerHTML}}/>*/}
                                                        {/*        </div>*/}
                                                        {/*}*/}
                                                        {value?.tagName === 'P' && (tweetId === null || tweetId === undefined)  ? (
                                                            <div className={'font-18 jost font-weight-400'} dangerouslySetInnerHTML={{ __html: value?.outerHTML }} />
                                                        ) : tweetId ? (
                                                            <div className="font-18 jost font-weight-400">
                                                                <TwitterTweetEmbed
                                                                    tweetId={tweetId}
                                                                    options={{
                                                                        cards: 'visible',
                                                                        align: 'center',
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={`w-100 ${isMobile ? 'text-center' : ' d-flex justify-content-center'}`}
                                                                dangerouslySetInnerHTML={{ __html: value?.outerHTML }}
                                                            />
                                                        )}
                                                        {index === showAdIndex &&
                                                            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                        {window.twittr ? window.twttr.widgets.load() : ''}
                                    </div>

                                    {renderPublishedBy()}
                                    {renderRelatedStories()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {target === 'fanzinesports' && <FanzineSportsNavBar/>}
            <Loader loading={loading}/>
            {/*{
                news && news.is_snack ?
                    <div
                        className={'iframe-content'}>
                        <iframe
                            height={'100%'}
                            width={'100%'}
                            onLoad={() => {
                                setLoading(false);
                            }
                            }
                            src={news ? news?.snack_link : ''}/>
                    </div>
                    :
                    <></>
            }*/}
            {
                news && news.is_mad ? renderSnack()
                    : <div
                        className={'iframe-content'}>
                        <iframe
                            height={'100%'}
                            width={'100%'}
                            onLoad={() => {
                                setLoading(false);
                            }
                            }
                            src={news &&
                                `https://fanzine.com/football/${news.main_team}/${news.slug}`}/>
                    </div>
            }
            <Footer/>
        </>
    );
};

export default withRouter(NewSingleNewsPage);
