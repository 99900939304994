import BGWhite from '../../assets/images/corporate/whiteBG.png';
import Slider from 'react-slick';
import React from 'react';

const FansLove = (props) => {
  const {isMobile} = props;
  const renderLaptop = () => {
    return (
        <div
            style={{
              paddingTop:'50px',
              backgroundImage: `url("${BGWhite}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: isMobile ? '1400px' : '797px',
            }}
        >
          <h1 className={'prometoFont pt-5'}
              style={{fontSize: isMobile ? '32px' : '50px'}}
          >
            THE <span className={'prometoFont'}
                      style={{color: '#74dbef'}}>FANS</span> LOVE US!
          </h1>
          <div className={'container'}>
            <div className={'row mt-3'}>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/comment/comment1.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                  height: '180px',
                  width: '401px',
                }}/>
              </div>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/comment/comment2.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                  height: '180px',
                  width: '367px',
                }}/>

              </div>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/comment/comment3.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                  height: '172px',
                  width: '372px',
                }}/>

              </div>
            </div>
            <div className={'row mt-5'}>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/comment/comment4.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                  height: '276px',
                  width: '367px',
                }}/>
              </div>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/comment/comment5.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                  height: '189px',
                  width: '377px',
                }}/>
              </div>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <img className={'img-fluid'} src={require(
                    '../../assets/images/corporate/comment/Group 4678.png')}
                     alt={'img1'} style={{
                  objectFit: 'contain',
                  height: '203px',
                  width: '390px',
                }}/>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobile = () => {

    const settings = {
      dots: false,
      className: isMobile ? '' : 'ps-5 ms-5',
      centerMode: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],      // centerPadding: "50px",
      // height:'590px',
      swipe: true,
      slidesToShow: isMobile ? 1 : 2.28,
      speed: 500,
    };

    const renderSlide1 = () => {
      return (
          <div className={'ps-1 pe-1'} style={{height: !isMobile && '590px'}}>
            <img
                className={'img-fluid'}
                style={{height: !isMobile && '590px', objectFit: 'contain'}}
                src={require(
                    '../../assets/images/corporate/comment/comment1.png')}
                alt={'img-1'}/>
          </div>
      );
    };
    const renderSlide2 = () => {
      return (
          <div className={'ps-1 pe-1'} style={{height: !isMobile && '590px'}}>
            <img
                className={'img-fluid'}
                style={{height: !isMobile && '590px', objectFit: 'contain'}}
                src={require(
                    '../../assets/images/corporate/comment/comment2.png')}
                alt={'img-1'}/>
          </div>
      );
    };
    const renderSlide3 = () => {
      return (
          <div className={'ps-1 pe-1'} style={{height: !isMobile && '590px'}}>
            <img
                className={'img-fluid'}
                style={{height: !isMobile && '590px', objectFit: 'contain'}}
                src={require(
                    '../../assets/images/corporate/comment/comment3.png')}
                alt={'img-1'}/>
          </div>
      );
    };
    const renderSlide4 = () => {
      return (
          <div className={'ps-1 pe-1'} style={{height: !isMobile && '590px'}}>
            <img
                className={'img-fluid'}
                style={{height: !isMobile && '590px', objectFit: 'contain'}}
                src={require(
                    '../../assets/images/corporate/comment/comment4.png')}
                alt={'img-1'}/>
          </div>
      );
    };
    const renderSlide5 = () => {
      return (
          <div className={'ps-1 pe-1'} style={{height: !isMobile && '590px'}}>
            <img
                className={'img-fluid'}
                style={{height: !isMobile && '590px', objectFit: 'contain'}}
                src={require(
                    '../../assets/images/corporate/comment/comment5.png')}
                alt={'img-1'}/>
          </div>
      );
    };
    const renderSlide6 = () => {
      return (
          <div className={'ps-1 pe-1'} style={{height: !isMobile && '590px'}}>
            <img
                className={'img-fluid'}
                style={{height: !isMobile && '590px', objectFit: 'contain'}}
                src={require(
                    '../../assets/images/corporate/comment/comment6.png')}
                alt={'img-1'}/>
          </div>
      );
    };

    return (
        <div
            style={{
              backgroundImage: `url("${BGWhite}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: isMobile ? '320px' : '797px',
            }}
            className={'bg-white'}>
          <h1 className={'prometoFont pt-5'}
              style={{fontSize: isMobile ? '32px' : '50px'}}
          >
            THE <span className={'prometoFont'}
                      style={{color: '#74dbef'}}>FANS</span> LOVE US!
          </h1>

          <Slider
              style={{width: '95%'}}
              {...settings}>
            {renderSlide1()}
            {renderSlide2()}
            {renderSlide3()}
            {renderSlide4()}
            {renderSlide5()}
            {renderSlide6()}
          </Slider>
        </div>
    );

  };

  return isMobile ? renderMobile() : renderLaptop();
};

export default FansLove;
