import Social from '../../assets/images/corporate/SocialBG.png';
import React from 'react';

const ByNumbers = (props) => {
  const {isMobile} = props;
  const renderLaptop = () => {
    return (
        <div
            style={{
              backgroundImage: `url("${Social}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '728px',
            }}
            className={'pt-5'}
        >

          <h1 className={'prometoFont text-white pt-5'}
              style={{fontSize: '50px'}}
          >
            BY NUMBERS</h1>
          <div className={'container'}>
            <div className={'row ms-5 mt-5'}>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <div
                    className={'justify-content-center align-items-center'}
                    style={{
                      backgroundColor: '#74dbef',
                      height: '187px', width: '328px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{

                        fontSize: '73px',

                      }}
                  >
                    5.2M
                  </h1>
                  <h3 className={'sfProDisplayFont'}>
                    Fans
                  </h3>
                </div>
              </div>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '187px', width: '328px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '73px',

                      }}
                  >
                    446M
                  </h1>
                  <h3 className={'sfProDisplayFont'}>
                    Monthly Impressions
                  </h3>

                </div>

              </div>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '187px', width: '328px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '73px',

                      }}
                  >
                    5,250
                  </h1>
                  <h3 className={'sfProDisplayFont'}>
                    Influencers
                  </h3>

                </div>

              </div>

            </div>
            <div className={'row ms-5 mt-5'}>
              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '187px', width: '328px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '73px',

                      }}
                  >
                    35K <span className={'prometoFont'}
                              style={{fontSize: '40px'}}>P/W</span>
                  </h1>
                  <h3 className={'sfProDisplayFont'}>
                    Organic Growth
                  </h3>

                </div>
              </div>

              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '187px', width: '328px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '73px',

                      }}
                  >
                    370K
                  </h1>
                  <h3 className={'sfProDisplayFont'}>
                    App Downloads
                  </h3>

                </div>

              </div>


              <div
                  className={'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-12 col-sm-12 '}>
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '187px', width: '328px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '73px',

                      }}
                  >
                    50
                  </h1>
                  <h3 className={'sfProDisplayFont'}>
                    Apps & Websites
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobile = () => {
    return (
        <div
            style={{
              backgroundImage: `url("${Social}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '580px',
            }}
            className={'pt-3'}
        >

          <h1 className={'prometoFont text-white pt-5'}
              style={{fontSize: '32px'}}
          >
            BY NUMBERS</h1>
          <div className={'container'}>
            <div className={'row mt-5'}>
              <div className={'col-6 p-2  '}>
                <div
                    className={'justify-content-center align-items-center'}
                    style={{
                      backgroundColor: '#74dbef',
                      height: '95px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '33px',

                      }}
                  >
                    5.2M
                  </h1>
                  <h6 className={'sfProDisplayFont'}>
                    Fans
                  </h6>
                </div>
              </div>
              <div className={'col-6 p-2  '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '95px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '33px',

                      }}
                  >
                    446M
                  </h1>
                  <h6 className={'sfProDisplayFont'}>
                    Monthly Impression
                  </h6>

                </div>

              </div>
              <div className={'col-6 p-2  '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '95px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '33px',

                      }}
                  >
                    5,250
                  </h1>
                  <h6 className={'sfProDisplayFont'}>
                    Influencers
                  </h6>

                </div>

              </div>
              <div className={'col-6 p-2  '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '95px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '33px',

                      }}
                  >
                    35K <span className={'prometoFont'}
                              style={{fontSize: '22px'}}>P/W</span>
                  </h1>
                  <h6 className={'sfProDisplayFont'}>
                    Organic Growth
                  </h6>

                </div>
              </div>

              <div className={'col-6 p-2  '}
              >
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '95px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '33px',

                      }}
                  >
                    370K
                  </h1>
                  <h6 className={'sfProDisplayFont'}>
                    App Downloads
                  </h6>

                </div>

              </div>

              <div className={'col-6 p-2  '}>
                <div
                    style={{
                      backgroundColor: '#74dbef',
                      height: '95px',
                    }}
                >
                  <h1
                      className={'prometoFont pt-3'}
                      style={{
                        fontSize: '33px',

                      }}
                  >
                    50
                  </h1>
                  <h6 className={'sfProDisplayFont'}>
                    Apps & Websites
                  </h6>
                </div>

              </div>
            </div>
          </div>
        </div>
    );
  };

  return isMobile ? renderMobile() : renderLaptop();

};

export default ByNumbers;
